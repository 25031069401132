<template>
  <div>
    <topmenu :comname="'associatedMedia'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>合作媒体</span>
      </div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <div v-if="isshowlist" class="listinfor" v-loading="loading">
          <ul>
            <li v-for="item in list" :key="item.id">
              <!-- <a
                :href="
                  'http://www.yyj086.com/Room/index?iUiIdentifier=' +
                  item.iUiIdentifier
                "
                target="_blank"
              > -->
              <el-image
                :src="
                  item.logoUrl
                    ? item.logoUrl
                    : require('@/assets/images/nologo.jpg')
                "
                alt="图片"
                fit="cover"
                style="width: 185px; height: 98px"
              ></el-image>
              <!-- </a> -->
            </li>
          </ul>
          <el-pagination
            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-count="totalPage"
            :current-page="currentPage"
            :page-size="pagesize"
            :hide-on-single-page="true"
            class="t_page"
          ></el-pagination>
        </div>
        <nocont v-else></nocont>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "associatedMedia",
  data() {
    return {
      list: [],
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize: 20, //每页显示条数
      totalPage: 0, //总页数
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont,
  },
  created() {
    this.getinformation();
  },
  watch: {
    //监听翻页
    currentPage: function (newVal, oldVal) {
      this.loading = true;
      this.getinformation();
    },
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
  },
  methods: {
    handleSizeChange: function (size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function (currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    getinformation() {
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true,
        iType: 1,
      };
      this.$axios.post("/api/app/exhibitionMedia/getExhibitionMediaPageList", data).then(res => {
        console.log(res,'p-p-p-p-')
        this.loading = false;
          this.totalPage = res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata);
            this.list = infordata;
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
      })
    },
  },
};
</script>
<style scoped>
.contm {
  text-align: left;
  padding: 0 5px 0 15px;
  float: left;
  width: 768px;
}
.tlew {
  font-size: 16px;
  line-height: 22px;
  color: #333;
}
.time {
  font-size: 15px;
  line-height: 30px;
  color: #999;
}
.gtna {
  width: 74px;
  height: 30px;
  background-color: #193155;
  border-radius: 2px;
  color: #fff;
  line-height: 30px;
  display: block;
}
.listinfor {
  overflow: hidden;
  padding-bottom: 20px;
}
.listinfor ul {
  padding: 40px 0;
  display: block;
  overflow: hidden;
}
.listinfor li {
  cursor: pointer;
  margin-left: 15px;
  margin-bottom: 15px;
  float: left;
  width: 185px;
  border: solid 1px #e4e4e4;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  position: relative;
  top: 0px;
  transition: all 0.5s ease-in-out;
}
.listinfor li:hover {
  top: -8px;
}
.listinfor li .el-image {
  display: block;
  overflow: hidden;
  border-bottom: solid 1px #e4e4e4;
}
.listinfor li span {
  display: block;
  line-height: 24px;
  height: 48px;
  padding: 5px 5px;
}
.t_page {
  margin-top: 30px;
}
</style>